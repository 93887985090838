<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Activate Account -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-card-text class="mt-2">
          <div class="text-center">
            <b-spinner
              v-show="!(successNotification || errorNotification)"
              variant="primary"
            />

            <notification
              variant="danger"
              :text="errorNotification"
              :show="errorNotification"
              :dismiss-secs="30"
            />

            <notification
              variant="success"
              text="Your account is now active!"
              :show="successNotification"
            />
          </div>
        </b-card-text>

      </b-card>
    <!-- /Activate Account -->
    </div>
  </div>

</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardText, BLink, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import Notification from '../../@core/components/Notification.vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BCardText,
    BLink,
    BSpinner,
    Notification,
  },
  data() {
    return {
      successNotification: null,
      errorNotification: null,
    }
  },
  mounted() {
    this.successNotification = null
    this.errorNotification = null

    useJwt.activate({
      userId: this.$route.params.userId,
      token: this.$route.params.token,
    }).then(() => {
      this.successNotification = true

      setTimeout(() => {
        window.location = 'https://uvi.gg'
      }, 5000)
    })
      .catch(error => {
        this.errorNotification = error.response.data.message
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
