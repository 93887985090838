<template>
  <img
    :src="logo"
    alt="UVI"
  >
</template>

<script>

export default {
  data() {
    return {
      logo: `${process.env.VUE_APP_BASE_URL}/logo.png`,
    }
  },
}

</script>
